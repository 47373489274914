<template>
  <div>
    <iframe src="http://localhost/model/4?grid=0.65&sepia=0&shadowIntensity=0&brightness=0&contrast=0&opacity=1&blendMode=skip&blockOpacity=1&pixar=NaN&isEnvImage=0&isSkyBoxImage=0"></iframe>
    <h1>Test Page</h1>
  </div>
</template>

<script>
export default {
  name: "MainView",
}
</script>
